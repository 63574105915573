import { URIs } from "../Common/Constants";
import { ILocationData } from "../Models/FileExplorer";
import { PreSignedURLI } from "../Models/PreSignedURL";

import GenericAPIService from "./GenericAPIService";

const {
  FILE_EXPLORER_LIST_DATA,
  FILE_EXPLORER_DOWNLOAD_DATA
} = URIs;
class FileExplorerService extends GenericAPIService {
  async listObjects(prefix:string='') {
    try {
      let params={}
      if(prefix){
        params={
         params:{
          prefixFolder:prefix
         }
        }
      }
      const { data } = await this.getAsync<{ data:ILocationData,message:string }>(FILE_EXPLORER_LIST_DATA,params);
      return data;
    } catch (error) {
      console.log("error in getting data", error);
    }
  }

   async downloadObjects(prefix:string='') {
    try {
      let params={}
      if(prefix){
        params={
         params:{
          filename:prefix
         }
        }
      }
      const { data } = await this.getAsync<{ data:PreSignedURLI,message:string }>(FILE_EXPLORER_DOWNLOAD_DATA,params);
      return data;
    } catch (error) {
      console.log("error in getting data", error);
    }
  }
}

export default FileExplorerService;
