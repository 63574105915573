import {
  AlertColor,
  Box,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import * as React from "react";

import GradientButton from "../../Components/Button/GradientButton";
import NoRecords from "../../Components/NoRecords/NoRecords";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import PageHeader from "../../Components/Text/PageHeader";
import TextInputField from "../../Components/TextInputField/TextInputField";
import { UserLoginContext } from "../../Contexts";
import { createMyViewModel } from "../../Models/CustomizedViewModel";
import CustomizedViewService from "../../Services/CustomizedViewService";
const CustomizedViewServices = new CustomizedViewService();
interface Props {
  showCustomisedDialog: boolean;
  handleModalClose: () => void;
}
interface IState {
  isLoading: boolean;
  sourceData: any;
  countriesData: any;
  searchSourcesText: string;
  searchGeogrphiesText: string;
  viewResponseData: any;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  isButtonLoading: boolean;
  countryRspData: any;
  sourceRspData: any;
  [key: string]: any;
}

class CustomizedViewPopover extends React.Component<Props, IState> {
  static contextType = UserLoginContext;
  context!: React.ContextType<typeof UserLoginContext>;
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      sourceData: [],
      countriesData: [],
      searchSourcesText: "",
      searchGeogrphiesText: "",
      viewResponseData: [],
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      isButtonLoading: false,
      countryRspData: [],
      sourceRspData: [],
    };
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });
      localStorage.setItem("isCustomizedView", "open");
      const viewResponse = await this.getAllView();
      const response =
        await CustomizedViewServices.getAllCountryAndSourceData();
      const resultObj = this.reduceData(response?.data.result);
      const { sourceData, countriesData } = this.mapData(
        resultObj,
        viewResponse
      );
      const filterSources = sourceData.some((el: any) => el.checked === true);
      const filterCountries = countriesData.some(
        (el: any) => el.checked === true
      );
      if (filterSources && filterCountries) {
        this.setState({ isModalOpen: true });
      }
      this.setState({
        sourceData,
        countriesData,
        sourceRspData: sourceData,
        countryRspData: countriesData,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  getAllView = async () => {
    const response = await CustomizedViewServices.getallMyViewData();
    return response?.data.data;
  };

  reduceData = (data: any) => {
    return data.reduce((acc: any, ele: any) => {
      if (!acc[ele.type]) {
        acc[ele.type] = [ele.col];
      } else {
        acc[ele.type].push(ele.col);
      }
      return acc;
    }, {});
  };

  mapData = (resultObj: any, viewResponse: any) => {
    const mapFunc = (arr: string[]) =>
      arr.map((el: string) => ({ name: el, checked: false }));

    let srcResultData = resultObj.SRC ? mapFunc(resultObj.SRC) : [];
    let countryResultData = resultObj.CNTRY ? mapFunc(resultObj.CNTRY) : [];

    if (viewResponse && viewResponse.length > 0) {
      srcResultData = this.mergeData(srcResultData, viewResponse[0].sources);
      countryResultData = this.mergeData(
        countryResultData,
        viewResponse[0].countries
      );
    }

    return { sourceData: srcResultData, countriesData: countryResultData };
  };

  mergeData = (data1: any, data2: any) => {
    return data1.map((obj1: any) => {
      for (let obj2 of data2) {
        if (obj1.name === obj2.name) {
          return { ...obj1, ...obj2 };
        }
      }
      return obj1;
    });
  };

  onToggleCountryCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    record: any
  ) => {
    const dataMapped = this.state.countriesData.map((el: any, ind: number) => {
      if (el.name === record.name) {
        return { ...el, checked: event.target.checked };
      }

      return el;
    });
    const rspDataMapped = this.state.countryRspData.map(
      (el: any, ind: number) => {
        if (el.name === record.name) {
          return { ...el, checked: event.target.checked };
        }

        return el;
      }
    );
    const filterData = dataMapped.filter((el: any) => el.checked === true);
    if (filterData.length <= 5) {
      this.setState({
        countriesData: dataMapped,
        countryRspData: rspDataMapped,
      });
    } else {
      this.setState({
        openAlert: true,
        alertMsg: "You can select upto 5 countries",
        alertType: "error",
      });
    }
  };
  onToggleSourceCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    record: any
  ) => {
    const dataMapped = this.state.sourceData.map((el: any, ind: number) => {
      if (el.name === record.name) {
        return { ...el, checked: event.target.checked };
      }

      return el;
    });
    const rspDataMapped = this.state.sourceRspData.map(
      (el: any, ind: number) => {
        if (el.name === record.name) {
          return { ...el, checked: event.target.checked };
        }

        return el;
      }
    );
    const filterData = dataMapped.filter((el: any) => el.checked === true);
    if (filterData.length <= 5) {
      this.setState({
        sourceData: dataMapped,
        sourceRspData: rspDataMapped,
      });
    } else {
      this.setState({
        openAlert: true,
        alertMsg: "You can select upto 5 sources",
        alertType: "error",
      });
    }
  };
  handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    searchTextKey: string,
    dataKey: string,
    rspDataKey: string
  ) => {
    const searchValue = event.target.value;
    this.setState({ ...this.state, [searchTextKey]: searchValue }, () => {
      if (searchValue.length > 0) {
        let filteredRes = this.state[rspDataKey].filter((el: any) =>
          el.name.toLowerCase().includes(searchValue.toLowerCase())
        );
      
        // Preserve the checked state
        // let filteredRes1 = filteredRes.map((el: any) => {
        //   const original = this.state[dataKey].find(
        //     (item: any) => item.name === el.name
        //   );
        
        //   return original ? { ...el, checked: original.checked } : el;
        // });
       
        this.setState({ ...this.state, [dataKey]: filteredRes });
      } else {
        let resetData = this.state[rspDataKey].map((el: any) => {
          const original = this.state[dataKey].find(
            (item: any) => item.name === el.name
          );
          return original ? { ...el, checked: original.checked } : el;
        });
        this.setState({ ...this.state, [dataKey]: resetData });
      }
    });
  };

  handleSourceSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.handleSearchChange(
      event,
      "searchSourcesText",
      "sourceData",
      "sourceRspData"
    );
  };

  handleCountrySearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.handleSearchChange(
      event,
      "searchGeogrphiesText",
      "countriesData",
      "countryRspData"
    );
  };

  handleDoneClick = async () => {
    try {
      this.setState({ isButtonLoading: true });
      const viewResponse = await this.getAllView();
      const payloadObj: createMyViewModel = {
        countries: this.state.countriesData.filter(
          (el: any) => el.checked === true
        ),
        sources: this.state.sourceData.filter((el: any) => el.checked === true),
      };

      let response;
      if (viewResponse.length > 0) {
        response = await CustomizedViewServices.updateMyViewData({
          setFields: payloadObj,
          id: { id: viewResponse[0].id },
        });
      } else {
        response = await CustomizedViewServices.createMyViewData(payloadObj);
      }

      if (response?.message) {
        this.setState(
          {
            openAlert: true,
            isButtonLoading: false,
            alertMsg: "Customized view details saved successfully.",
            alertType: "success",
            isModalOpen: true,
          },
          () => {
            this.props.handleModalClose();
            localStorage.setItem("isCustomizedView", "close");
          }
        );
      } else {
        this.setState({
          openAlert: true,
          isButtonLoading: false,
          alertMsg: "Something went wrong.",
          alertType: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  isEmptyCheck = () => {
    const { sourceData, countriesData, sourceRspData, countryRspData } =
      this.state;
    const checkedSources = sourceData.filter((el: any) => el.checked === true);
    const checkedCountries = countriesData.filter(
      (el: any) => el.checked === true
    );
    const checkedsourceRspData = sourceRspData.filter(
      (el: any) => el.checked === true
    );
    const checkedcountryRspData = countryRspData.filter(
      (el: any) => el.checked === true
    );
    if (checkedsourceRspData.length > 0 || checkedcountryRspData.length > 0) {
      return false;
    } else {
      if (checkedSources.length === 0 || checkedCountries.length === 0) {
        return true;
      } else {
        return false;
      }
    }
  };
  isDoneButtonDisabled = () => {
    const { sourceData, countriesData } = this.state;
    const checkedSources = sourceData.filter((el: any) => el.checked === true);
    const checkedCountries = countriesData.filter(
      (el: any) => el.checked === true
    );

    if (checkedSources.length === 0 || checkedCountries.length === 0) {
      return true;
    } else {
      return false;
    }
  };
  render() {
    const {
      searchSourcesText,
      searchGeogrphiesText,
      sourceData,
      countriesData,
      isLoading,
      alertMsg,
      alertType,
      isButtonLoading,
      openAlert,
    } = this.state;
    return (
      <Card>
        <CardContent>
          <SnackbarAlert
            alertType={alertType}
            open={openAlert}
            message={alertMsg}
            onClose={() => {
              this.setState({ openAlert: false });
            }}
          />

          <Grid item xs={6} md={8}>
            <PageHeader label="Customized View" />
          </Grid>

          <hr style={{ border: "1px solid #D1D1D1", marginBottom: "2em" }} />

          {isLoading ? (
            <Grid item display="flex" justifyContent="center" width="35em">
              <CircularProgress
                disableShrink
                sx={{ color: "#d52b1e", marginTop: 4, marginBottom: 4 }}
              />
            </Grid>
          ) : (
            <>
              <Grid container columns={12} spacing={3}>
                <Grid item xs={6} className="SearchNameInput">
                  <Typography fontWeight={"bold"}>
                    Select Geographies{" "}
                  </Typography>
                  <div
                    style={{
                      border: "1px solid #9e9e9e",
                      width: "240px",
                      borderRadius: "4px",
                    }}
                  >
                    <TextInputField
                      id="Search"
                      placeholder="Search"
                      value={searchGeogrphiesText}
                      onChange={this.handleCountrySearchChange}
                    />
                    <div
                      style={{
                        maxHeight: 350,
                        overflowY: "scroll",
                        paddingTop: "10px",
                      }}
                    >
                      {countriesData.length > 0 ? (
                        <>
                          {countriesData.map((el: any, index: any) => {
                            return (
                              <Grid item display="flex" key={index}>
                                <Checkbox
                                  tabIndex={-1}
                                  checked={el.checked}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => this.onToggleCountryCheckbox(event, el)}
                                  sx={{
                                    "&.MuiCheckbox-root": {
                                      padding: "5px",
                                    },
                                    "&.Mui-checked": {
                                      color: "#3DA470",
                                    },
                                    "& .MuiSvgIcon-root": {
                                      borderRadius: "4px",
                                    },
                                  }}
                                />
                                <Typography
                                  style={{
                                    wordBreak: "break-all",
                                    wordWrap: "break-word",
                                  }}
                                  textTransform={"capitalize"}
                                  sx={{
                                    "&.MuiTypography-root": {
                                      lineHeight: 0,
                                    },
                                    p: 2,
                                    cursor: "pointer",
                                  }}
                                >
                                  {el.name}
                                </Typography>
                              </Grid>
                            );
                          })}
                        </>
                      ) : (
                        <Box style={{ marginTop: "2vh", marginBottom: "2vh" }}>
                          <NoRecords />
                        </Box>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} className="SearchNameInput">
                  <Typography fontWeight={"bold"}>Select Sources </Typography>
                  <div
                    style={{
                      border: "1px solid #9e9e9e",
                      width: "240px",
                      borderRadius: "4px",
                    }}
                  >
                    <TextInputField
                      id="Search"
                      placeholder="Search"
                      value={searchSourcesText}
                      onChange={this.handleSourceSearchChange}
                    />

                    <div
                      style={{
                        maxHeight: 350,
                        overflowY: "scroll",
                        paddingTop: "10px",
                      }}
                    >
                      {sourceData.length > 0 ? (
                        <>
                          {sourceData.map((el: any, index: any) => {
                            return (
                              <Grid item display="flex" key={index}>
                                <Checkbox
                                  tabIndex={-1}
                                  checked={el.checked}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => this.onToggleSourceCheckbox(event, el)}
                                  sx={{
                                    "&.MuiCheckbox-root": {
                                      padding: "5px",
                                    },
                                    "&.Mui-checked": {
                                      color: "#3DA470",
                                    },
                                    "& .MuiSvgIcon-root": {
                                      borderRadius: "4px",
                                    },
                                  }}
                                />
                                <Typography
                                  style={{
                                    wordBreak: "break-all",
                                    wordWrap: "break-word",
                                  }}
                                  textTransform={"capitalize"}
                                  sx={{
                                    "&.MuiTypography-root": {
                                      lineHeight: 0,
                                    },
                                    p: 2,
                                    cursor: "pointer",
                                  }}
                                >
                                  {el.name}
                                </Typography>
                              </Grid>
                            );
                          })}
                        </>
                      ) : (
                        <Box style={{ marginTop: "2vh", marginBottom: "2vh" }}>
                          <NoRecords />
                        </Box>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                columnGap={1.5}
                justifyContent={"flex-end"}
                mt={3}
              >
                <GradientButton
                  disabled={this.isDoneButtonDisabled()}
                  isButtonLoad={isButtonLoading}
                  label="Done"
                  onClick={this.handleDoneClick}
                />
              </Grid>
            </>
          )}
        </CardContent>
      </Card>
    );
  }
}
export default CustomizedViewPopover;
