import { Box, Typography } from '@mui/material'
import React, { FC } from 'react'
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";


interface PropsI {
    msg?: string;
    style?: React.CSSProperties;
}

export const NoReports: FC<PropsI> = ({ msg, style }) => {
    return (
        <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", ...style }}>
            <Typography>  <InsertDriveFileIcon sx={{ color: "#273d68" }} /></Typography>
            <Box fontWeight={"bold"} textAlign={"center"}>{msg ?? 'No Reports to Display'}</Box>
        </Box>
    )
}