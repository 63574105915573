import { AuthClaimModel, AuthTokenModel } from "../Models/AuthClaimModel";
import AuthProvider from "./AuthProvider";
import GraphAuthProvider from "./GraphAuthProvider";

class AuthProviderService {
    private readonly _CallbackPathName = 'callbackPath';
    
    getCallbackPath(): string {
        const path = localStorage.getItem(this._CallbackPathName);
        if (path) {
            return path;
        }
        return '/';
    }

    login() {
        AuthProvider.login();
    }

    logout() {
        AuthProvider.signout();
    }


    handlerPromise(callback: () => void) {
        AuthProvider.handlePromiseRedirectCallback(callback);
    }

    async isLoggedIn(): Promise<boolean> {
        return AuthProvider.isLoggedIn();
    }
    
    async getAccessToken(): Promise<AuthTokenModel> {
        return AuthProvider.getAccessToken();
    }

    async getGraphAccessToken(): Promise<AuthTokenModel> {
        return GraphAuthProvider.getAccessToken();
    }

    getClaims(): AuthClaimModel {
        return AuthProvider.getIdTokenClaims();
    }

    setCallbackPath(path?: string | readonly string[]) {
        if (typeof path === 'string') {
            localStorage.setItem(this._CallbackPathName, path);
        } else {
            localStorage.setItem(this._CallbackPathName, '/');
        }
    }

}

export default new AuthProviderService();