import { Box, CircularProgress, Typography } from "@mui/material";
import { withRouter } from "react-router-dom";
import FolderIcon from "@mui/icons-material/Folder";
import { useCallback, useEffect, useState } from "react";
import { ContentTypeT, ILocationData } from "../../Models/FileExplorer";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DownloadIcon from "@mui/icons-material/Download";
import FileExplorerService from "../../Services/FileExplorerService";
import BreadCrumbWithCallback from "../../Components/BreadCrumb/BreadCrumbWithCallback";
import { NoReports } from "../../Components/NoReports/NoReports";

const fileExplorer = new FileExplorerService();

const location: ILocationData = {
    breadCrumb: '',
    folders: [],
    files: [],
};

const FileExplorer = () => {
    const [content, setContent] = useState<ILocationData>(location);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const noContent: boolean = content.files.length === 0 && content.folders.length === 0;

    async function getBucketData(prefix: string = "") {
        try {
            setIsLoading(true);
            const bucketResponse = await fileExplorer.listObjects(prefix);
            setIsLoading(false);
            if (bucketResponse) {
                const { folders, files } = bucketResponse.data;
                const parentURL: string | undefined = folders[0]?.split("/")?.filter(un => un)?.shift()
                setContent({ breadCrumb: prefix || parentURL || '', folders, files });
            }
        } catch { }
    }

    async function downloadBucketData(prefix: string = "") {
        try {
            const bucketResponse = await fileExplorer.downloadObjects(prefix);
            if (bucketResponse) {
                const url: string | undefined = bucketResponse.data.presigned_url;
                if (!url)
                    return;
                const a: HTMLAnchorElement = document.createElement('a');
                a.href = url;
                a.click();
            }
        } catch { }

    }

    function downloadReport(prefix: string) {
        downloadBucketData(prefix).then();
    }

    function changeLocation(type: ContentTypeT, name: string) {
        if (isLoading) return;
        if (type === "folders")
            getBucketData(name).then();
    }

    function breadCrumbHandler(url: string) {
        getBucketData(url).then();
    }

    useEffect(() => {
        getBucketData().then();
    }, []);

    const drawContent = useCallback(
        (type: ContentTypeT) => {
            return content[type].map((name) => {
                const nameArr: string[] = name.split("/");
                let parsedName: string | undefined = nameArr.pop() || nameArr.pop();
                return (
                    <Box
                        sx={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "space-between",
                            cursor: type === "folders" ? "pointer" : "",
                            alignItems: "center",
                            padding: 1,
                            borderRadius: 1,
                            backgroundColor: "rgb(204, 213, 221)",
                            margin: "4px"
                        }}
                        onClick={() => changeLocation(type, name)}
                    >
                        <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
                            {type === "folders" ?
                                <FolderIcon fontSize="large" sx={{ color: "#273d68" }} />
                                :
                                <InsertDriveFileIcon
                                    fontSize="large"
                                    sx={{ color: "#273d68" }}
                                />
                            }
                            <Typography
                                className="folder-text"
                                sx={{
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    userSelect: "none",
                                }}
                            >
                                {parsedName}
                            </Typography>
                        </Box>
                        {
                            type === 'files' && <Box sx={{ width: "50%" }}>
                                <DownloadIcon fontSize="medium" sx={{ color: "red", cursor: "pointer" }} onClick={() => downloadReport(name)} />
                            </Box>
                        }
                    </Box >
                );
            });
        },
        [content]
    );

    return (
        <Box
            sx={{
                margin: "30px 0 0 0",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                borderRadius: "8px",
                height: "75vh",
                padding: "10px",
                position: "relative"
            }}
        >
            {isLoading ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                    }}
                >
                    <CircularProgress
                        sx={{
                            color: "#d52b1e",
                        }}
                    />
                </Box>
            ) : (
                <Box sx={{ overflowY: "hidden", height: "100%" }}>
                    <BreadCrumbWithCallback bread={content.breadCrumb} cssStyle={{ padding: "10px 5px" }} handler={breadCrumbHandler} />

                    <Box sx={{
                        height: "92%",
                        overflowY: "auto"
                    }}> {noContent ? (
                        <NoReports
                            style={{
                                height: "100%",
                                justifyContent: "center",
                            }}
                        />
                    ) : (
                        <>
                            {drawContent("folders")}
                            {drawContent("files")}
                        </>
                    )}
                    </Box>
                </Box>
            )}
        </Box>
    );
};


export default withRouter(FileExplorer);
