import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import { Box, CircularProgress, Tooltip, Typography } from "@mui/material";
import reportMappingData from "./reportMapping.json";
import moment from "moment";
import DashboardService from "../../Services/DashboardService";
const DashboardServices = new DashboardService();
interface Props extends RouteComponentProps<any, any, any> { }

interface State {
  tooltipVisible: boolean;
  selectedMarker: any;
  selectedRegion: any;
  markersData: any[];
  isReportLoading: boolean;
}
class Map extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      tooltipVisible: false,
      selectedMarker: null,
      selectedRegion: null,
      markersData: [],
      isReportLoading: false,
    };
  }
  async componentDidMount() {
    this.setState({ isReportLoading: true });
    const response = await DashboardServices.getReportingData();
    const seggData: any = response.data.result.map((el: any) => {
      const val = reportMappingData.find(
        (val: any) => el.report === val.region
      );
      return {
        id: el.rcrd_key,
        name: val?.region,
        subReport: el.sub_report,
        coordinates: val?.coordinates,
        rpt_end_dt: el.rpt_deadline_ddmm,
      };
    });

    let result = seggData.reduce((acc: any, item: any) => {
      let existing = acc.find((x: any) => x.name === item.name);
      if (!existing) {
        acc.push({
          ...item,
          rptDetails: [
            { subReport: item.subReport, rptEndDt: item.rpt_end_dt },
          ],
        });
      } else {
        existing.rptDetails.push({
          subReport: item.subReport,
          rptEndDt: item.rpt_end_dt,
        });
      }
      return acc;
    }, []);
    this.setState({ markersData: result, isReportLoading: false });
  }
  handleMarkerClick = (markerId: any) => {
    this.setState({ selectedMarker: markerId, tooltipVisible: true });
  };
  calculateDays = (dateArr: any) => {
    return dateArr.some((el: any) => {
      const endDate = moment(el.rptEndDt, "DD-MM-YYYY");
      const currentDate = moment(new Date());
      const diff = endDate.diff(currentDate, "days");

      return diff >= 0 && diff < 90;
    });
  };
  isReportExpiring = (dates: any, targetDate: any) => {
    const today = moment();
    let closestDate = moment();
    let minDiff = Infinity;

    dates.forEach((dateObj: any) => {
      const date = moment(dateObj.rptEndDt, "DD-MM");
      date.year(today.year());

      if (date.isBefore(today)) {
        date.add(1, "years");
      }

      const diff = date.diff(today, "days");

      if (diff < minDiff) {
        minDiff = diff;
        closestDate = date;
      }
    });

    // Check if the closest date is the target date and it is within the next 90 days
    return (
      closestDate.format("DD-MM") === targetDate && minDiff >= 0 && minDiff < 90
    );
  };

  dayDiffCalculate = (date: any) => {
    const endDate = moment(date, "DD-MM-YYYY");
    const currentDate = moment(new Date());
    const diff = endDate.diff(currentDate, "days");
    return diff >= 0 && diff < 90;
  };
  render() {
    const { markersData, isReportLoading } = this.state;

    return (
      <>
        {isReportLoading ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "12vh",
              marginBottom: "12vh",
              overflow: "hidden",
            }}
          >
            <CircularProgress sx={{ color: "#d52b1e" }} />
          </Box>
        ) : (
          <Box >
            <ComposableMap
              projectionConfig={{
                center: [20, 25]
              }}
              xHeight={100}
              data-tip=""
            >
              <Geographies geography="countriesSVG.json">
                {({ geographies }) =>
                  geographies.map((geo) => (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={
                        this.state.selectedRegion === geo.rsmKey
                          ? "red"
                          : "#00a9d3"
                      }
                    />
                  ))
                }
              </Geographies>
              {markersData.map((el: any) => (
                <Tooltip
                  key={el.id}
                  arrow
                  open={this.state.selectedMarker === el.id}
                  style={{
                    background: "#2E2F30",
                    zIndex: "9999999999!important",
                  }}
                  className="MapTooltip"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        overflow: "visible",
                        bgcolor: "#2E2F30",
                        zIndex: "9999999999!important",

                        "& .MuiTooltip-arrow": {
                          color: "#2E2F30",
                          zIndex: "9999999999!important",
                        },
                      },
                    },
                  }}
                  title={
                    <>
                      <Typography fontWeight={"bold"} fontSize={"12px"}>
                        {el.name}
                      </Typography>
                      {el.rptDetails.map((val: any, ind: number) => {
                        return (
                          <Typography
                            key={ind}
                            fontSize={"10px"}
                            style={{
                              color: this.isReportExpiring(
                                el.rptDetails,
                                val.rptEndDt
                                //el.name
                              )
                                ? "#ffed28"
                                : "",
                            }}
                          >
                            Report Deadline Date: {val.rptEndDt}{" "}
                            {val.subReport ? `(${val.subReport})` : ""}
                          </Typography>
                        );
                      })}
                    </>
                  }
                >
                  <Marker
                    key={el.id}
                    coordinates={el.coordinates}
                    onMouseEnter={() => this.handleMarkerClick(el.id)}
                    onMouseLeave={() => this.handleMarkerClick("")}
                    className={
                      this.calculateDays(el.rptDetails) ? "BlinkMarker" : ""
                    }
                  >
                    <g
                      fill="none"
                      stroke="#EAA50A"
                      strokeWidth="7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      transform="translate(-12, -24)"
                    >
                      <desc cx="12" cy="10" r="3" />
                      <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                    </g>
                    <text
                      textAnchor="middle"
                      y={el.markerOffset}
                      style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
                    ></text>
                  </Marker>
                </Tooltip>
              ))}
            </ComposableMap>
          </Box >
        )
        }
      </>
    );
  }
}

export default withRouter(Map);
