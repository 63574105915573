
import React, { CSSProperties, FC, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Stack } from '@mui/material';


interface Props {
  bread: string;
  cssStyle?: CSSProperties;
  handler: (url: string) => void;
}

const BreadCrumbWithCallback: FC<Props> = ({ bread, cssStyle, handler }) => {

  function parseURL(index: number) {
    const parsedURL: string = `${getSplittedURL().slice(0, index + 1).join('/')}/`
    handler(parsedURL);
  }

  function getSplittedURL(): string[] {
    const splitValue: string[] = bread.split("/").filter(un => un);
    return splitValue;
  }

  const splittedURL: string[] = useMemo(() => {
    if (!bread)
      return [bread];
    const splitValue: string[] = getSplittedURL();
    return splitValue;
  }, [bread]);


  return (
    <Stack spacing={2} sx={{ ...cssStyle }}>
      <Breadcrumbs
        separator=">"
        aria-label="breadcrumb"
      >
        {splittedURL.map((urlName: string, index: number) => (<div role="presentation" onClick={() => parseURL(index)}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography sx={{
              color: 'text.primary',
              fontSize: "16px",
              fontWeight: 600,
              '&:hover': {
                textDecoration: 'underline',
                cursor: "pointer"
              }
            }}>{urlName}</Typography>
          </Breadcrumbs>
        </div>))}
      </Breadcrumbs>
    </Stack>
  );
}

export default BreadCrumbWithCallback;
